import "@/styles/tailwind.css";
import "@/styles/chrome-bug.css";
import "@/styles/react-calendar.css";
import "@/styles/cookie-bot.css";
import "@/styles/suma.css";
import "@/styles/progress-wheel.css";
import "@/styles/suma-slider.css";
import "@/styles/react-horizontal-scrolling-menu.css";
// import 'shepherd.js/dist/css/shepherd.css';
import "@/styles/shepherd.css";
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";

import { ReactElement, ReactNode } from "react";
import { getAppBaseUrl, isBrowser, isProd } from "@/utils/app";

import type { AppProps } from "next/app";
import { AppRouter } from "@/server/routers/_app";
import Head from "next/head";
import type { NextPage } from "next";
import Page from "@/components/pages/Page";
//import PrivyScript from '@/components/script/PrivyScript';
import { ReactQueryDevtools } from "react-query/devtools";
import SegmentScript from "@/components/script/SegmentScript";
import { SelectedTabProvider } from "@/context/SelectedTabOnDashboardBrowseContext";
// import ZendeskScript from "@/components/script/ZendeskScript";
import { httpBatchLink } from "@trpc/client/links/httpBatchLink";
import { loggerLink } from "@trpc/client/links/loggerLink";
import { useEffect } from "react";
import { withTRPC } from "@trpc/next";
import HotjarScript from "@/components/script/HotjarScript";
// import * as amplitude from '@amplitude/analytics-browser';
// import useUser from '@/hooks/useUser';
// import { pageViewTrackingEnrichment } from '@/hooks/amplitude/enrichments/enrichmentPageView';
import MetaPixelScript from "@/components/script/MetaPixelScript";
import SuperJSON from "superjson";
import TailwindBreakpoint from "@/components/atoms/@next/TailwindBreakpoint";
import { FormProvider } from "@/context/FormContext";
// import useRunOneSignal from '@/utils/runOneSignal';

export type NextPageWithLayout<T = any | undefined> = NextPage<T> & {
  getLayout?: (page: ReactElement) => ReactNode;
};

export type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout;
};

const ReelWebApp = ({ Component, pageProps }: AppPropsWithLayout) => {
  useEffect(() => {
    document.body.classList?.remove("loading");
  }, []);

  const getLayout = Component.getLayout ?? ((page) => page);

  return (
    <>
      <Head>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, maximum-scale=1"
        />
      </Head>

      <SelectedTabProvider>
        <FormProvider>
          <Page>{getLayout(<Component {...pageProps} />)}</Page>
        </FormProvider>

        <ThirdPartyScripts />
      </SelectedTabProvider>

      {process.env.NODE_ENV === "development" ? (
        <TailwindBreakpoint positionClasses="bottom-1 right-1" />
      ) : null}
    </>
  );
};

const ThirdPartyScripts = () => {
  //const { enablePrivy } = useFlags();

  return (
    <>
      {!isProd() && isBrowser() && <ReactQueryDevtools initialIsOpen={false} />}

      {/* {isProd() && isBrowser() && <ZendeskScript />} */}

      {/* {enablePrivy && <PrivyScript />} */}

      <SegmentScript />
      <HotjarScript />
      <MetaPixelScript />
    </>
  );
};

export default withTRPC<AppRouter>({
  config() {
    return {
      queryClientConfig: {
        defaultOptions: {
          queries: {
            refetchOnWindowFocus: true,
          },
        },
      },
      links: [
        loggerLink({
          enabled: (opts) => {
            // Only log errors in production
            if (isProd()) {
              return opts.direction === "down" && opts.result instanceof Error;
            }

            if (opts.direction === "up") {
              return true;
            }

            // Ignores aborted requests. Aborting requests is desired
            // behavior, but TRPC displays them as errors, annoyingly
            // filling up your console on route change or when you log
            // out.
            return (
              !(opts.result instanceof Error) ||
              opts.result?.originalError?.name !== "TRPCAbortError"
            );
          },
        }),
        httpBatchLink({
          url: `${getAppBaseUrl()}/api/trpc`,
          maxBatchSize: 10,
        }),
      ],
      transformer: SuperJSON,
    };
  },
  ssr: false,
})(ReelWebApp);
